import React from "react"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const TermsField = ({ document }) => {
  return <Wrap>{renderRichText(document, {})}</Wrap>;
}

const Wrap = styled.div`
  padding: 0;
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: normal;
  color: ${(props) => props.color || `#323232`};
  margin-bottom: 20px;
`

const A = styled.a`
  color: #e40000;
  text-decoration: underline;
  font-weight: bold;
`

export default TermsField

import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { motion } from "framer-motion"

const WineResult = ({
  position,
  title,
  description,
  image,
  value,
  points,
  disclaimer,
}) => {
  const variants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        delay: position * 0.25,
        ease: "circOut",
      },
    },
  }

  return (
    <Wrap initial="hidden" animate="visible" variants={variants}>
      <ImageWrap>
        {image && image.fluid && <Image fluid={image.fluid} objectFit="contain" />}
      </ImageWrap>
      <Top>
        <Title>{title}</Title>
        <Details>{description.text}</Details>
      </Top>
      <Bottom>
        {value && <EquivalentValueLabel>equivalent value:</EquivalentValueLabel>}
        {value && <EquivalentValue>{value}</EquivalentValue>}
        <Points>{points}</Points>
        <Terms>{disclaimer}</Terms>
      </Bottom>
    </Wrap>
  )
}

const Wrap = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 360px;
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  }
`

const ImageWrap = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
`

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`

const Top = styled.div`
  text-align: left;
  display: flex;
  height: 168px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  background-color:white;
`

const Bottom = styled.div`
  background-color: #f8f8f8;
  height: 84px;
  display: flex;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const Title = styled.h3`
  color: #323232;
  font-size: 18px;
  line-height: 20px;
  margin-top: 14px;
  margin-bottom: 10px;
`

const EquivalentValueLabel = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: #666666;
  left: 16px;
  top: 16px;
  position: absolute;
`

const EquivalentValue = styled.span`
  font-size: 22px;
  text-transform: uppercase;
  color: #323232;
  left: 16px;
  top: 32px;
  position: absolute;
  font-weight: bold;
`

const Points = styled.span`
  font-size: 22px;
  text-transform: uppercase;
  color: #e40000;
  right: 16px;
  top: 32px;
  position: absolute;
  font-weight: bold;
  display: flex;

  &::after {
    content: "PTS";
    font-size: 10px;
    padding-top: 2px;
    padding-left: 2px;
  }
`

const Details = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #666666;
`

const Terms = styled.p`
  font-size: 10px;
  color: #666666;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
`

export default WineResult

import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { motion } from "framer-motion"

import Layout from "./layout"
import { H1 } from "./ui/text"
import Button from "./ui/buttons/button"
import Carousel from "./ui/carousel"
import TextField from "./ui/textfield"
import TermsField from "./ui/terms"
import FlightResult from "./ui/results/flight"
import WineResult from "./ui/results/wine"
import StoreResult from "./ui/results/store"
import HotelResult from "./ui/results/hotel"
import RailResult from "./ui/results/rail"

export default class extends React.Component {

  render() {

    let search = ``
    let campaign = ``
    let shape = null

    if (typeof window !== `undefined`) {
      search = window.location.search
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('campaign')) campaign = urlParams.get('campaign')
      if(urlParams.get('shape') === 'true') shape = "true"
    }

    const {
      heading,
      buttons,
      results,
      description,
      termsAndConditions,
      navigation,
    } = this.props.data.screen

    const { screens } = this.props.data
    const urls = []

    screens.edges.forEach((edge, i) => urls.push(edge.node))

    return (
      <Layout>
        <Wrap shape={shape}>
          <Text>
            <H1
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  opacity: 0,
                  y: 20,
                },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    ease: "circOut",
                  },
                },
              }}
            >
              {heading}
            </H1>
            <TFWrap
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  opacity: 0,
                  y: 20,
                },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.3,
                    ease: "circOut",
                  },
                },
              }}
            >
              {description && (
                <TextField document={description} urls={urls} search={search} />
              )}
            </TFWrap>
          </Text>
          {buttons && (
            <Buttons>
              <ButtonList>
                {buttons.map((button, i) => (
                  <Link
                    className="btn"
                    to={`/screens/${button.screenLink.slug}${search}`}
                    key={i}
                  >
                    <Button
                      type={button.buttonType}
                      position={i}
                      label={button.label}
                      icon={button.icon}
                      image={button.image}
                    />
                  </Link>
                ))}
              </ButtonList>
            </Buttons>
          )}
          {results && (
            <Carousel shape={shape}>
              {results.map((result, i) => {
                let DOM = ``

                if (result.__typename === "ContentfulFlight") {
                  DOM = (
                    <FlightResult
                      key={i}
                      position={i}
                      title={result.title}
                      image={result.image}
                      departure={result.departure}
                      arrival={result.arrival}
                      via={result.via}
                      cabinType={result.cabinType}
                      tripType={result.tripType}
                      value={result.value}
                      points={result.points}
                      pointsDisclaimerPrice={result.pointsDisclaimerPrice}
                      pointsDisclaimerCopy={result.pointsDisclaimerCopy}
                      businessClass={result.businessClass}
                    />
                  )
                }

                if (result.__typename === "ContentfulWine") {
                  DOM = (
                    <WineResult
                      key={i}
                      position={i}
                      title={result.title}
                      image={result.image}
                      description={result.description}
                      value={result.value}
                      points={result.points}
                      disclaimer={result.disclaimer}
                    />
                  )
                }

                if (result.__typename === "ContentfulStore") {
                  DOM = (
                    <StoreResult
                      key={i}
                      position={i}
                      title={result.title}
                      image={result.image}
                      description={result.description}
                      value={result.value}
                      points={result.points}
                      disclaimer={result.disclaimer}
                    />
                  )
                }

                if (result.__typename === "ContentfulHotel") {
                  DOM = (
                    <HotelResult
                      key={i}
                      position={i}
                      title={result.title}
                      image={result.image}
                      roomType={result.roomType}
                      guests={result.guests}
                      duration={result.duration}
                      value={result.value}
                      points={result.points}
                      disclaimer={result.disclaimer}
                    />
                  )
                }

                if (result.__typename === "ContentfulRail") {
                  DOM = (
                    <RailResult
                      key={i}
                      position={i}
                      title={result.title}
                      image={result.image}
                      cabinType={result.cabinType}
                      destination={result.destination}
                      duration={result.duration}
                      value={result.value}
                      points={result.points}
                      disclaimer={result.disclaimer}
                    />
                  )
                }

                return DOM
              })}
            </Carousel>
          )}
          <Bottom
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                opacity: 0,
                y: 20,
              },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1.5,
                  delay: 1.5,
                  ease: "circOut",
                },
              },
            }}
          >
            {termsAndConditions && <TermsField document={termsAndConditions} />}
            {navigation && navigation.includes("RESET") && <ButtonLabel>Want to see what else you could use your points for?</ButtonLabel>}
            <ButtonWrapper>
              {navigation && navigation.includes("BACK") && (
                <a onClick={() => window.history.back()}>
                  <Button label="previous" type="outline" />
                </a>
              )}
              {navigation && navigation.includes("RESET") && (
                <Link to={`/campaign/${campaign}${search}`}>
                  <Button label="start over" type="solid" />
                </Link>
              )}
            </ButtonWrapper>
          </Bottom>
        </Wrap>
      </Layout>
    )
  }
}

const Wrap = styled.div`
  ${up("md")} {

    ${props => props.shape ? `
      background:rgba(255,255,255,0.32);
      border-radius:10px;
      
      border:1px solid rgba(255,255,255,0.4);
      padding-top:30px;
      padding-bottom:20px;
      margin-bottom:10px;
      box-shadow: 0px 5px 11px 1px rgba(0,0,0,0.05);
    ` : `
    
    `}
    
  }
`

// backdrop-filter: blur(5px);
// -webkit-backdrop-filter: blur(5px);

const Text = styled.div`
  padding-left: 30px;
  padding-right: 30px;

  ${up("md")} {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
`

const Buttons = styled.div`
  padding-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom:30px;
`

const ButtonList = styled.ul`
  ${up("md")} {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  /* ${up("lg")} {
    padding-left: 80px;
    padding-right: 80px;
  } */
`

const TFWrap = styled(motion.div)``

const Bottom = styled(motion.div)`
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  text-align: center;
  
  ${up("lg")} {
    padding-top:0px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  ${up("md")} {
    flex-direction: row;
  }
`

const ButtonLabel = styled.p`
  font-size:14px;
  color:#323232;
  margin-top:0px;

  ${up("md")} {
    font-size:18px;
  }
`

export const ScreenQuery = graphql`
  query ScreenQuery($slug: String!) {
    screen: contentfulScreen(slug: { eq: $slug }) {
      heading
      slug
      description {
        raw
      }
      buttons {
        screenLink {
          slug
        }
        label
        buttonType
        icon {
          file {
            url
          }
        }
        image {
          fluid(maxWidth: 320, quality: 85) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      navigation
      termsAndConditions {
        raw
      }
      results {
        ... on ContentfulFlight {
          title
          image {
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          departure
          arrival
          via
          cabinType
          tripType
          value
          points
          pointsDisclaimerPrice
          pointsDisclaimerCopy
          businessClass
        }
        ... on ContentfulWine {
          title
          image {
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            text: description
          }
          value
          points
          disclaimer
        }
        ... on ContentfulStore {
          title
          image {
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            text: description
          }
          value
          points
          disclaimer
        }
        ... on ContentfulHotel {
          title
          image {
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          roomType
          guests
          duration
          value
          points
          disclaimer
        }
        ... on ContentfulRail {
          title
          image {
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          destination
          duration
          cabinType
          value
          points
          disclaimer
        }
      }
    }
    screens: allContentfulScreen(limit: 1000) {
      edges {
        node {
          contentful_id
          slug
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { motion } from "framer-motion"

import { MultiTripIcon, UpgradeIcon } from "../icon"

const FlightResult = ({
  position,
  title,
  image,
  departure,
  arrival,
  via,
  cabinType,
  tripType,
  value,
  points,
  pointsDisclaimerPrice,
  pointsDisclaimerCopy,
  businessClass
}) => {
  const variants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        delay: position * 0.25,
        ease: "circOut",
      },
    },
  }

  return (
    <Wrap initial="hidden" animate="visible" variants={variants}>
      <ImageWrap>
        {businessClass && <BusinessClass>Business class</BusinessClass>}
        {image && image.fluid && <Image fluid={image.fluid} />}
      </ImageWrap>
      <Top>
        <Title>{title}</Title>
        <FlightPath>
          <FlightLabels>
            <FlightDepartureLabel>departure</FlightDepartureLabel>
            <FlightArrivalLabel>arrival</FlightArrivalLabel>
          </FlightLabels>
          <FlightDetails>
            <FlightDeparture>{departure}</FlightDeparture>
            <FlightIcon>
              <FlightIconSVG
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 51 7"
              >
                <g transform="translate(0 14)" fill="none" stroke="#8de2e0">
                  <path strokeLinecap="square" d="M50.3-10.5h-43" />
                  <circle cx={3.8} cy={-10.5} r={3} strokeWidth={0.95} />
                </g>
              </FlightIconSVG>
            </FlightIcon>
            <FlightStop>{via}</FlightStop>
            <FlightIcon>
              <FlightIconSVG
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 51 7"
              >
                <g transform="translate(0 14)" fill="none" stroke="#8de2e0">
                  <path strokeLinecap="square" d="M43.8-10.5H.8" />
                  <circle cx={47.3} cy={-10.5} r={3} strokeWidth={0.95} />
                </g>
              </FlightIconSVG>
            </FlightIcon>
            <FlightArrival>{arrival}</FlightArrival>
          </FlightDetails>
        </FlightPath>
        <Details>
          <Detail>
            <UpgradeIcon />
            {cabinType}
          </Detail>
          <Detail>
            <MultiTripIcon />
            {tripType}
          </Detail>
        </Details>
      </Top>
      <Bottom>
        {value && <EquivalentValueLabel>equivalent value:</EquivalentValueLabel>}
        {value && <EquivalentValue>{value}</EquivalentValue>}
        <Points>{points}</Points>
        <Price>
          <PriceAmount>{pointsDisclaimerPrice}</PriceAmount>
          <PriceDisclaimer>{pointsDisclaimerCopy}</PriceDisclaimer>
        </Price>
      </Bottom>
    </Wrap>
  )
}

const Wrap = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 360px;
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  }
`

const ImageWrap = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  background-color: #f8f8f8;
  position: relative;
`

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`

const Top = styled.div`
  text-align: left;
  display: flex;
  height: 168px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  background-color:white;
`

const Bottom = styled.div`
  background-color: #f8f8f8;
  height: 72px;
  display: flex;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const Title = styled.h3`
  color: #323232;
  font-size: 18px;
  line-height: 20px;
  margin-top: 14px;
  margin-bottom: 10px;
`

const EquivalentValueLabel = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: #666666;
  left: 16px;
  top: 20px;
  position: absolute;
`

const EquivalentValue = styled.span`
  font-size: 22px;
  text-transform: uppercase;
  color: #323232;
  left: 16px;
  top: 40px;
  position: absolute;
  font-weight: bold;
`

const Points = styled.span`
  font-size: 22px;
  text-transform: uppercase;
  color: #e40000;
  right: 16px;
  top: 16px;
  position: absolute;
  font-weight: bold;
  display: flex;

  &::after {
    content: "PTS";
    font-size: 10px;
    padding-top: 2px;
    padding-left: 2px;
  }
`

const Price = styled.div`
  right: 16px;
  top: 42px;
  position: absolute;
  text-align: right;
  display: flex;
  flex-direction: row;
`

const PriceDisclaimer = styled.span`
  font-size: 10px;
  line-height: 10px;
  color: #666666;
  max-width: 66px;
  display: flex;
`

const PriceAmount = styled.span`
  font-size: 20px;
  color: #323232;
  display: flex;
`

const FlightPath = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const FlightLabels = styled.div`
  display: flex;
  justify-content: space-between;
`

const FlightDepartureLabel = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: #666666;
`

const FlightArrivalLabel = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: #666666;
`

const FlightDetails = styled.div`
  display: flex;
  flex-direction: row;
`

const FlightDeparture = styled.div`
  text-align: left;
  flex-grow: 1;
  font-size: 22px;
  color: #323232;
  font-weight: bold;
`

const FlightIcon = styled.div`
  display: flex;
  width: 22%;
  height: 22px;
  align-items: center;
`

const FlightIconSVG = styled.svg`
  width: 100%;
`

const FlightStop = styled.div`
  text-align: center;
  flex-grow: 1;
  font-size: 10px;
  color: #666666;
  font-weight: bold;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  display: flex;
`

const FlightArrival = styled.div`
  text-align: right;
  flex-grow: 1;
  font-size: 22px;
  color: #323232;
  font-weight: bold;
`

const Details = styled.ul`
  list-style: none;
`

const Detail = styled.li`
  font-size: 12px;
  color: #666666;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  text-align: left;

  svg {
    height: 18px;
    margin-right: 6px;
  }
`

const BusinessClass = styled.div`
  border-top-left-radius:4px;
  border-bottom-right-radius:6px;
  background-color:#323232;
  color:white;
  position:absolute;
  top:0px;
  left:0px;
  z-index:10;
  padding:5px 8px;
`

export default FlightResult

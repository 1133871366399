import React from "react"
import styled from "styled-components"
import { INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { up } from "styled-breakpoints"

const TextField = ({ document, urls, search }) => {
  const findScreenById = (id) => {
    let s = `#`

    urls.forEach((url) => {
      if (id === url.contentful_id) {
        s = url.slug
      }
    })

    return s
  }

  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        return (
          <A href={`/screens/${findScreenById(node.data.target.sys.id)}${search}`}>
            {node.content[0].value}
          </A>
        )
      },
    },
  }

  return <Wrap>{renderRichText(document, options)}</Wrap>
}

const Wrap = styled.div`
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  color: ${(props) => props.color || `#666666`};

  ${up("md")} {
    font-size: 22px;
    line-height: 30px;
  }

  p {
    margin: 0;
  }
`

const A = styled.a`
  color: #e40000;
  text-decoration: underline;
  font-weight: bold;
`

export default TextField

import React from "react"
import styled from "styled-components"

const SVG = styled.svg``

export const MultiTripIcon = ({ size }) => (
  <SVG
    //   width={48}
    //   height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h48v48H0z" />
      <path
        d="M40.1 14.69a4.83 4.83 0 01-6.79-6.79L28.41 3 3 28.41l4.9 4.9a4.83 4.83 0 016.79 6.79l4.9 4.9L45 19.59l-4.9-4.9z"
        stroke="#323232"
        strokeWidth={2}
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.467 24.318c.02.006.032-.001.045-.008.024-.014.037-.021.046-.061a.093.093 0 00-.059-.103c-2.385-.757-4.924-1.016-7.405-.693l-.762.098c-.4.043-.794-.156-1.009-.528-.754-1.391 2.502-2.896 2.444-3.733l-.017-.144a6.537 6.537 0 00-2.94.04c.01.046.075.214.416.41.362.2.27.92-.605.708l-.072-.01c-6.362-1.552-6.759 2.126-12.178-.824-.046-.024-.09-.016-.113.031-.022.047-.012.092.023.124 5.384 4.25 9.204.492 12.704 5.335.136.178.36.254.558.173 2.729-1.028 5.78-1.356 8.912-.808l.012-.007"
        fill="#E40000"
      />
      <path
        d="M10.86 24.51l.98.98m2.87 2.94l.98.98m2.94 2.94l.98.98m2.94 2.87l.98.98"
        stroke="#8DE2E0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SVG>
)

export const UpgradeIcon = ({ size }) => (
  <SVG viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h48v48H0z" />
      <path
        d="M14.805 26.59L9.908 13.553l-.89-10.306c-.098-.83.2-1.246.89-1.246h6.019c.528.055.867.391 1.017 1.009.15.617.38 1.815.69 3.593.048.77-.182 1.368-.69 1.792-.509.424-1.35 1.144-2.521 2.16-.454.617-.454 1.233 0 1.846.453.613 3.81 5.344 10.07 14.19h-9.688zM34.44 30.94l5.56-.523c1.239-.095 1.906.588 2 2.049.094 1.46-.572 2.256-2 2.388L36.2 35l-1.76-4.06z"
        stroke="#323232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        stroke="#323232"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.234 35.5L14.457 46H34V35.5z"
      />
      <path
        d="M30.917 25.5c.825.013 1.434.41 1.825 1.193.39.782 1.948 3.806 4.671 9.072.019.823-.37 1.235-1.166 1.235h-19.59c-.852-.296-1.352-.786-1.5-1.469-.15-.683-.649-3.344-1.498-7.983-.065-.8.285-1.2 1.05-1.2l16.208-.848z"
        stroke="#323232"
        strokeWidth={2}
        fill="#8DE2E0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SVG>
)

export const HotelIcon = ({ size }) => (
  <SVG
    // width={48}
    // height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h48v48H0z" />
      <rect fill="#E40000" x={30} y={30} width={8} height={12} rx={1.5} />
      <rect
        stroke="#323232"
        strokeWidth={2}
        x={4}
        y={9}
        width={18}
        height={36}
        rx={1}
      />
      <path
        d="M22 11.033V4a1 1 0 011-1h22a1 1 0 011 1v40a1 1 0 01-1 1H1"
        stroke="#323232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <g
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M37 22h3M28 22h3M37 16h3M28 16h3M37 10h3M28 10h3" />
      </g>
      <g
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M10.5 30h5M10.5 23h5M10.5 16h5" />
      </g>
    </g>
  </SVG>
)

export const BedIcon = ({ size }) => (
  <SVG
    // width={48}
    // height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h48v48H0z" />
      <g
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M2 13v27.359M46 37H7" />
      </g>
      <path
        stroke="#323232"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M46 22.359v18"
      />
      <path
        d="M44 26c.552 0 1.052.224 1.414.586.362.362.586.862.586 1.414h0v5H2v-5c0-.552.224-1.052.586-1.414A1.994 1.994 0 014 26h0z"
        stroke="#323232"
        strokeWidth={2}
        fill="#7ED2D0"
      />
      <path
        d="M6 18h3.5a2.5 2.5 0 012.5 2.5V22a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 011-1z"
        fill="#E40000"
      />
    </g>
  </SVG>
)

export const GroupIcon = ({ size }) => (
  <SVG
    // width={15}
    // height={13}
    viewBox="0 0 15 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0-1h15v15H0z" />
      <g fillRule="nonzero">
        <path
          d="M5.312 5.92c.16 0 .293.12.31.276l.003.036v.75a.313.313 0 01-.622.037L5 6.983 5 6.233c0-.173.14-.313.312-.313zm-2.186 0c.16 0 .292.121.31.277l.002.036v.75a.313.313 0 01-.623.036l-.002-.037v-.75c0-.172.14-.312.313-.312z"
          fill="#323232"
        />
        <g fill="#323232">
          <path d="M6.68 2.582v.32l.004.001c.255.104.387.344.424.66l.006.057.004.072c.002.036.002.074.003.124v.054c0 .652-.21 1.129-.65 1.27l-.04.012-.027.079A2.746 2.746 0 015.38 6.545l-.085.056c-.63.375-1.328.375-2.058.012l-.017-.01a2.514 2.514 0 01-1.129-1.382l-.016-.05-.034-.01c-.433-.136-.662-.597-.678-1.207l-.002-.074c0-.383.15-.773.43-.944l.01-.005v-.36C1.802 1.068 2.92.25 4.315.25c1.325 0 2.326.79 2.365 2.244v.088zm-4.253.578c0 .173-.14.313-.313.313-.026 0-.126.197-.126.407 0 .448.12.675.308.696l.03.002c.148 0 .275.103.306.248.11.516.434.963.896 1.233l-.012-.006c.548.273 1.022.273 1.452.017.46-.294.787-.75.916-1.274a.313.313 0 01.264-.235l.036-.003c.187-.002.3-.188.311-.621v-.152l-.001-.062-.003-.053c-.013-.16-.03-.193-.103-.198h-.02a.313.313 0 01-.31-.276l-.002-.037v-.577c0-1.133-.709-1.707-1.741-1.707-1.06 0-1.85.562-1.886 1.619l-.002.076v.59z" />
          <path d="M5.203 1.5c.588 0 1.01.245 1.26.647.134.213.197.423.218.588l.006.053-.624.05-.003-.028a.929.929 0 00-.126-.332c-.14-.223-.363-.353-.731-.353-.304 0-.519.069-.818.244l-.132.08-.205.13c-.577.357-1.063.528-1.804.545l-.119.001V2.5c.643 0 1.034-.119 1.509-.401l.347-.217c.43-.264.76-.382 1.222-.382z" />
        </g>
        <path
          d="M2.666 7.438c.347.526.864.79 1.553.79.432 0 .803-.104 1.112-.31a2.214 2.214 0 00-.321 1.111l-.005.007a1.55 1.55 0 00-.318.957l.002.071.004.078.011.107-4.079.001c.022-.993.33-2.43 1.278-2.65l.065-.013.698-.15z"
          fill="#323232"
        />
        <path
          d="M2.42 8.13l.057.058c.447.44 1.04.665 1.742.665l.098-.001c.255-.008.496-.046.72-.114a2.11 2.11 0 00-.027.291l-.005.007a1.486 1.486 0 00-.276.588l-3.426.001.012-.076c.126-.749.416-1.289.756-1.346l.348-.074z"
          fill="#8DE2E0"
        />
      </g>
      <path
        d="M11.875 5.92c.16 0 .292.12.31.276l.002.036.001.75a.313.313 0 01-.623.037l-.002-.036v-.75c0-.173.14-.313.312-.313zm-2.186 0c.16 0 .292.121.31.277l.002.036-.001.75a.313.313 0 01-.623.036l-.002-.037v-.75c.001-.172.141-.312.314-.312z"
        fill="#323232"
        fillRule="nonzero"
      />
      <g fill="#323232" fillRule="nonzero">
        <path d="M13.243 2.582v.32l.004.001c.254.104.386.344.423.66l.006.057.005.072.002.124v.054c0 .652-.209 1.129-.65 1.27l-.04.012-.027.079a2.746 2.746 0 01-1.023 1.314l-.085.056c-.629.375-1.327.375-2.058.012l-.017-.01a2.514 2.514 0 01-1.128-1.382l-.016-.05-.035-.01c-.432-.136-.661-.597-.678-1.207l-.001-.074c0-.383.15-.773.43-.944l.01-.005v-.36C8.365 1.068 9.482.25 10.877.25c1.326 0 2.326.79 2.365 2.244l.001.088zM8.99 3.16c0 .173-.14.313-.313.313-.026 0-.127.197-.127.407 0 .448.12.675.308.696l.03.002c.148 0 .275.103.306.248.11.516.435.963.897 1.233l-.012-.006c.547.273 1.021.273 1.451.017.46-.294.788-.75.916-1.274a.313.313 0 01.265-.235l.035-.003c.187-.002.301-.188.312-.621v-.152c0-.023 0-.043-.002-.062l-.003-.053c-.013-.16-.03-.193-.103-.198h-.02a.313.313 0 01-.31-.276l-.002-.037v-.577c0-1.133-.708-1.707-1.74-1.707-1.06 0-1.85.562-1.887 1.619l-.001.076v.59z" />
        <path d="M11.967.646c0-.17-.167-.13-.293-.16-.169-.04-.312-.095-.33.134l-.008.107-.011.08-.009.046-.01.051c-.048.21-.133.42-.264.614-.382.565-1.079.909-2.216.909a.312.312 0 100 .625c1.213 0 2.06-.362 2.582-.984l.035-.042.006.028c.185.754.587 1.247 1.266 1.279l.071.001a.313.313 0 000-.625c-.395 0-.638-.322-.758-.935a4.298 4.298 0 01-.07-.904l.002-.073.002-.026.004-.064.001-.061z" />
      </g>
      <path
        d="M12.38 7.438c.149.049.332.094.558.134 1.437.255 1.437 2.678 1.437 2.678h-4.077c.01-.085.014-.173.014-.264V9.88a1.924 1.924 0 00-.008-.128l-.007-.065-.012-.077a1.338 1.338 0 00-.26-.6l-.039-.045.002.044-.01-.15a2.298 2.298 0 00-.297-.949c.297.212.664.318 1.1.318.69 0 1.222-.264 1.6-.79z"
        fill="#E40000"
      />
      <g fill="#323232" fillRule="nonzero">
        <path d="M7.554 7.438c1.002 0 1.775.56 1.81 1.6l.002.077v.147l.022.01c.166.096.26.27.288.484l.006.05c.005.056.005.098.005.18 0 .469-.164.827-.504.946l-.025.008-.021.054c-.15.353-.41.659-.752.881l-.08.05c-.481.265-1.014.265-1.567.01l-.017-.008a1.837 1.837 0 01-.85-.938l-.013-.036-.01-.004c-.337-.112-.52-.455-.534-.896l-.002-.06c0-.263.1-.53.288-.68l.034-.025v-.18c0-1.086.86-1.67 1.92-1.67zM6.259 9.505a.313.313 0 01-.242.305l-.037.006.002.003-.003.007a.383.383 0 00-.041.166c0 .251.056.356.143.37h.02c.145 0 .272.101.304.243.073.317.291.596.605.767L7 11.367c.38.175.704.175.995.015.316-.187.539-.473.625-.8a.313.313 0 01.261-.229l.038-.003c.092 0 .144-.088.144-.364V9.9l-.003-.039a.134.134 0 010-.044l-.005.001a.313.313 0 01-.31-.276l-.003-.036v-.39c0-.699-.473-1.053-1.187-1.053-.73 0-1.261.344-1.293.982l-.002.062v.4zM5.918 11.813a.312.312 0 01.075.62l-.036.004-.432.027c-.326.015-.494.16-.522.285l-.004.024a.312.312 0 11-.623-.046c.032-.43.427-.826 1.045-.883l.07-.004.427-.027zM9.082 11.813a.312.312 0 00-.075.62l.036.004.432.027c.326.015.494.16.522.285l.004.024a.312.312 0 10.623-.046c-.032-.43-.427-.826-1.045-.883l-.07-.004-.427-.027z" />
        <path d="M8.433 8.114l.489.39c-.52.65-1.427.962-2.704.965H6.09l.008-.625c1.13.015 1.888-.22 2.293-.68l.042-.05z" />
      </g>
    </g>
  </SVG>
)

export const BaggageIcon = ({ size }) => (
  <SVG
    // width={48}
    // height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h48v48H0z" />
      <path
        d="M17 14v-4 4zM23 39H4a3 3 0 01-3-3V13a3 3 0 013-3c.001 0 .002-.019.004-.019L42 10c1.655.002 3.039 1.37 3.039 3.026L45 14"
        stroke="#323232"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.02 9.897L17 6c0-1.221.758-2 2-2h8c1.242 0 2 .779 2 2v4M8 10v32"
        stroke="#323232"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.036 31h-6.078a.959.959 0 01-.96-.956v-9.066c0-.254.1-.497.28-.676l3.04-3.022a.965.965 0 011.359 0l3.039 3.022c.18.18.281.422.281.676v9.066c0 .528-.43.956-.961.956z"
        fill="#E40000"
        fillRule="nonzero"
      />
      <g>
        <path
          d="M30 40v2-2zm13 0v2-2z"
          stroke="#323232"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fill="#8DE2E0" d="M28 35l18 .007V39H28z" />
        <path
          d="M30.006 19.003l14 .026a3 3 0 012.994 3V37a3 3 0 01-3 3H30a3 3 0 01-3-3V22.003a3 3 0 013.006-3zM34 19v-2.87c0-.828.504-1.13 1.125-1.13h3.75c.621 0 1.125.302 1.125 1.13V19"
          stroke="#323232"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </SVG>
)

export const TrainIcon = ({ size }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M35.7 3C38.1 3 40 4.9 40 7.3v26.4c0 2.4-1.9 4.3-4.3 4.3h.1l4 6.5c.3.5.2 1.1-.3 1.4-.4.3-1 .2-1.3-.2l-.1-.1-1.5-2.6H11.4l-1.6 2.5c-.3.5-.9.6-1.4.3-.4-.2-.5-.8-.3-1.2l.1-.1 4-6.5C9.9 38 8 36.1 8 33.7V7.3C8 4.9 9.9 3 12.3 3h23.4zm-2.3 35H14.6l-1.9 3h22.6l-1.9-3zM38 28H10v5.7c0 1.3 1 2.3 2.3 2.3h23.5c1.2 0 2.3-1 2.3-2.3V28zm-24 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm20 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4-17H10v13h28V13zm-2.3-8H12.3C11 5 10 6 10 7.3V11h28V7.3C38 6 37 5 35.7 5z"
        fill="#323232"
      />
      <path fill="#8de2e0" d="M12 15h24v5z" />
      <path
        d="M29 7c.6 0 1 .4 1 1 0 .5-.4.9-.9 1H19c-.6 0-1-.4-1-1 0-.5.4-.9.9-1H29z"
        fill="#e40000"
      />
    </g>
  </SVG>
)

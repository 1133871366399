import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import SwiperCore, { Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/navigation/navigation.scss"

SwiperCore.use([Pagination, Navigation])

export default class Carousel extends React.Component {

  render() {

    const { children, shape } = this.props

    let tablet = false
    let showCarousel = false
    let hPadding = 0

    if(children.length < 2) hPadding = 16

    if (typeof window !== `undefined`) {
      tablet = (window.innerWidth > 400 && window.innerWidth < 769) ? true : false
      console.log('tablet:' + tablet)
      console.log('children.length:' + children.length)
    }

    if (tablet) {
      if (children.length < 3) showCarousel = false
      if (children.length > 2) showCarousel = true
    }

    if (!tablet) {
      if (children.length < 4) showCarousel = false
      if (children.length > 3) showCarousel = true
    }

    return (
      <Wrap>
        <Small>
          <Swiper
            slidesOffsetBefore={(children.length > 1 ? 20 : 0)}
            slidesOffsetAfter={(children.length > 1 ? 20 : 0)}
            spaceBetween={20}
            slidesPerView={(children.length > 1 ? 1.2 : 1)}
            centeredSlides={(children.length > 1 ? false : true)}
            pagination={{ clickable: true }}
            style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: hPadding, paddingRight: hPadding }}
          >
            {this.props.children.map((child, i) => {
              return <SwiperSlide key={i}>{child}</SwiperSlide>
            })}
          </Swiper>
        </Small>
        <Medium>
          {showCarousel && (
            <>
              <NextButton className="swiper-button-next" boxed={(shape ? true : false)}>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                      <path fill="white" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  </svg>
              </NextButton>
              <PrevButton className="swiper-button-prev" boxed={(shape ? true : false)}>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                      <path fill="white" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                  </svg>
              </PrevButton>
              <Swiper
                slidesOffsetBefore={20}
                slidesOffsetAfter={20}
                spaceBetween={20}
                slidesPerView={3.2}
                pagination={{ clickable: true }}
                style={{ paddingTop: 10, paddingBottom: 10 }}
                centeredSlides={false}
                navigation={{ prevEl: ".swiper-button-prev", nextEl: ".swiper-button-next"}}
              >
                {this.props.children.map((child, i) => {
                  return <SwiperSlide key={i}>{child}</SwiperSlide>
                })}
              </Swiper>
            </>
          )
          }
          {!showCarousel && (
            <Wrapper>
              {this.props.children.map((child, i) => {
                return <ItemWrap key={i}>{child}</ItemWrap>
              })}
            </Wrapper>
          )
          }
        </Medium>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  padding-top: 14px;
  padding-bottom: 10px;

  .swiper-button-disabled {
    opacity: 1 !important;
    cursor: auto;
    pointer-events: none;
    background: rgb(218, 218, 218) !important;
  }
`

const Small = styled.div`
  display:block;

  ${up('md')}{
    display:none;
  }
`

const Medium = styled.div`
  display:none;

  ${up('md')}{
    display:block;
  }
`

const Wrapper = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:center;
  width:100%;
  padding-top:10px;
  padding-bottom:20px;
`

const ItemWrap = styled.div`
  margin-left:10px;
  margin-right:10px;
  max-width:300px;
  width:100%;
`

const NextButton = styled.div`
    border-radius: 20px;
    background: rgb(50, 50, 50);
    width: 40px;
    height: 40px;
    color: rgb(255, 255, 255);
    z-index: 10;
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    right:${props => props.boxed ? `-20px` : `-10px`};
    top:${props => props.boxed ? `360px` : `320px`};
    transition: all 0.2s ease;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &::after{
      display:none !important;
    }

    &:hover{
      transform:scale(1.05);
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    }
`

const PrevButton = styled.div`
    border-radius: 20px;
    background: rgb(50, 50, 50);
    width: 40px;
    height: 40px;
    color: rgb(255, 255, 255);
    z-index: 10;
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    left:${props => props.boxed ? `-20px` : `-10px`};
    top:${props => props.boxed ? `360px` : `320px`};
    transition: all 0.2s ease;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &::after{
      display:none !important;
    }

    &:hover{
      transform:scale(1.05);
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    }
`


// @media (orientation: landscape) {
//   body {
//     flex-direction: row;
//   }
// }

// @media (orientation: portrait) {
//   body {
//     flex-direction: column;
//   }
// }